@import '~bootstrap/scss/bootstrap';

$keedle-blue: #2a388f;
$ka-black: #212529;

.bg-keedleblue {
    background-color: $keedle-blue;
    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%), inset 0 -1px 0 rgb(0 0 0 / 15%);
}

.kd-layout {
    display: grid;
    grid-template-areas: 
    "nav nav"
    "sidebar main"
    "sidebar main"
    "login login";
    grid-template-columns: 1.25fr 5.75fr;
}

.kd-nav {
    grid-area: nav;
}
.kd-login{
    grid-area: login;
}
.kd-sidebar {
    grid-area: sidebar;
    height: calc(100vh - 4rem);
}

.kd-main {
    grid-area: main;
    padding-top: .5rem;
}

.sidebar-link {
    display: block;
    text-decoration: none;
    color: $ka-black;
    padding: .375rem;

    &:hover, &:focus, &:active {
        background-color: rgba(0,0,0,0.08);
        border-radius: 10px;
    }
}

.text-smnote {
    font-size: 13px;
}

.text-14 {
    font-size: 14px;
}